<template>
    <div id="ResidentialServiceDetailView">
        <v-container class="business-detail-block" fluid>
            <div class="block-wrapper">
                <v-row no-gutters class="business-data">
                    <v-col cols="12" lg="7">
                        <h1 class="bold mb-5">{{business_data.name}}</h1>
                        <h6 class="bold mb-15">{{business_data.subname}}</h6>
                        <div class="business-tags mb-10">
                            <template v-for="tag in business_data.tags">
                                <div class="business-tag smithy" v-if="tag == 'Residencial'">
                                    <span class="">{{tag}}</span>
                                </div>
                                <div class="business-tag business" v-if="tag == 'Herreria'">
                                    <span class="">{{tag}}</span>
                                </div>
                            </template>
                        </div>
                        <p>{{business_data.description}}</p>
                    </v-col>
                    <v-col></v-col>
                    <v-col cols="12" lg="4" class="d-flex align-center justify-center">
                        <div class="business-buy-cta">
                            <h6 class="bold text-center mb-5">¡Cotízalo!</h6>
                            <div>
                                <div class="business-buy-phone mr-5">
                                    <v-img src="@/assets/img/icons/llamanos.png" contain width="30"></v-img>
                                    <p class="mt-3">Llámanos</p>
                                </div>
                                <div class="business-buy-whatsapp">
                                    <v-img src="@/assets/img/icons/whatsapp-contactanos.png" contain width="30">
                                    </v-img>
                                    <p class="mt-3">Contáctanos</p>
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
                <v-row no-gutters class="mt-10">
                    <v-col cols="12" lg="6" class="pr-7" v-for="business_photo in business_photos">
                        <div class="business-photo-container">
                            <zoom-on-hover class="business-photo rounded-img"
                                :img-normal="require('@/assets/img/business/' + business_photo.photo)"
                                :img-zoom="require('@/assets/img/business/' + business_photo.photo)"
                                :scale="2">
                            </zoom-on-hover>
                        </div>
                    </v-col>
                </v-row>

                <v-row no-gutters class="mt-10">
                    <v-col cols="6" lg="3" class="pr-7" v-for="business_photo in business_photos_2">
                        <div class="business-photo-container">
                            <zoom-on-hover class="business-photo rounded-img"
                                :img-normal="require('@/assets/img/business/' + business_photo.photo)"
                                :img-zoom="require('@/assets/img/business/' + business_photo.photo)"
                                :scale="2">
                            </zoom-on-hover>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </v-container>
    </div>
</template>

<style lang="scss">
    @import "@/assets/scss/BusinessServiceDetail.scss";
</style>

<script>
    export default {
        name: "FurnitureDetailView",

        components: {},

        data: () => ({
            image_open: false,
            selected_img: null,
            main_image: null,
            business_data: {
                sku: "COD 8999",
                name: 'Techumbres residenciales y Palapas',
                subname: "Diversos Proyectos",
                description: 'Techumbres residenciales y Palapas (diversos Proyectos) Suministro e instalación de techos residenciales y pérgolas. Suministro de materiales, trazos, corte, soldadura, detallado y aplicación de pintura automotriz',
                materials: "Estructura de metal y MDF con chapa de abeto.",
                tags: ['Residencial', 'Herreria'],
                specs: {
                    large: "120 cm",
                    width: "60 cm",
                    height: "75 cm"
                },
            },
            business_photos: [{
                    photo: 'Empresarial1.png',
                },
                {
                    photo: 'Empresarial2.png',
                },
            ],
            business_photos_2: [{
                    photo: 'Empresarial1.png',
                },
                {
                    photo: 'Empresarial2.png',
                },
                {
                    photo: 'Empresarial2.png',
                },
                {
                    photo: 'Empresarial2.png',
                },
            ],
        }),
        methods: {}
    }
</script>