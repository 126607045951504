<template>
    <div id="ResidentialServiceDetailView">
        <v-container class="residential-detail-block" fluid>
            <div class="block-wrapper">
                <v-row no-gutters class="residential-data">
                    <v-col cols="12" lg="7">
                        <h1 class="bold mb-5">{{residential_data.name}}</h1>
                        <h6 class="bold mb-15">{{residential_data.subname}}</h6>
                        <div class="residential-tags mb-10">
                            <template v-for="tag in residential_data.tags">
                                <div class="residential-tag smithy" v-if="tag == 'Residencial'">
                                    <span class="">{{tag}}</span>
                                </div>
                                <div class="residential-tag residential" v-if="tag == 'Herreria'">
                                    <span class="">{{tag}}</span>
                                </div>
                            </template>
                        </div>
                        <p class="">{{residential_data.description}}</p>
                    </v-col>
                    <v-col></v-col>
                    <v-col cols="12" lg="4" class="d-flex align-center justify-center">
                        <div class="residential-buy-cta">
                            <h6 class="bold text-center mb-5">¡Cotízalo!</h6>
                            <div>
                                <div class="residential-buy-phone mr-5">
                                    <v-img src="@/assets/img/icons/llamanos.png" contain width="30"></v-img>
                                    <p class="mt-3">Llámanos</p>
                                </div>
                                <div class="residential-buy-whatsapp">
                                    <v-img src="@/assets/img/icons/whatsapp-contactanos.png" contain width="30">
                                    </v-img>
                                    <p class="mt-3">Contáctanos</p>
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
               <!--  <v-row class="mt-10">
                    <v-col cols="12" v-for="residential_photo in residential_photos">
                        <div class="residential-photo-container">
                            <zoom-on-hover class="residential-photo rounded-img"
                                :img-normal="require('@/assets/img/residential/doors/' + residential_photo.photo)"
                                :img-zoom="require('@/assets/img/residential/doors/' + residential_photo.photo)"
                                :scale="2">
                            </zoom-on-hover>
                        </div>
                    </v-col>
                </v-row> -->
                <v-row no-gutters class="mt-10">
                    <v-col class="grid-6" cols="12" lg="6" v-for="residential_photo in residential_photos_2">
                        <div class="residential-photo-container">
                            <zoom-on-hover class="residential-photo rounded-img"
                                :img-normal="require('@/assets/img/residential/doors/' + residential_photo.photo)"
                                :img-zoom="require('@/assets/img/residential/doors/' + residential_photo.photo)"
                                :scale="2">
                            </zoom-on-hover>
                        </div>
                    </v-col>
                </v-row>

            </div>
        </v-container>
    </div>
</template>

<style lang="scss">
    @import "@/assets/scss/ResidentialServiceDetail.scss";
</style>

<script>
    export default {
        name: "FurnitureDetailView",

        components: {},

        data: () => ({
            image_open: false,
            selected_img: null,
            main_image: null,
            residential_data: {
                sku: "COD 8999",
                name: 'Techumbres residenciales y Palapas',
                subname: "Diversos Proyectos",
                description: 'Techumbres residenciales y Palapas (diversos Proyectos) Suministro e instalación de techos residenciales y pérgolas. Suministro de materiales, trazos, corte, soldadura, detallado y aplicación de pintura automotriz',
                materials: "Estructura de metal y MDF con chapa de abeto.",
                tags: ['Residencial', 'Herreria'],
                specs: {
                    large: "120 cm",
                    width: "60 cm",
                    height: "75 cm"
                },
                specs_photo: "Puertas1.png",
            },
            residential_photos: [{
                    photo: 'Img1.png',
                },
            ],
            residential_photos_2: [{
                    photo: 'Img1.png',
                },
                {
                    photo: 'Img2.png',
                },
            ],
        }),
        methods: {}
    }
</script>