<template>
  <div id="Footer">
    <v-container fluid fill-height class="footer-block">
      <div class="block-wrapper">
        <v-row no-gutters>
          <v-col cols="12" lg="6">
            <v-img width="20rem" :src="require('@/assets/img/logos/' + web_logo)"></v-img>
          </v-col>
          <v-col cols="12" lg="3">
            <div class="footer-block__schedule">
              <h6 class="bold txt-white mb-5">Horarios</h6>
              <div class="mb-5">
                <p class="txt-white bold mr-3">Lun - Sab : </p>
                <p class="txt-white">9 AM - 8 PM</p>
              </div>
              <div>
                <p class="txt-white bold mr-3">Dom :</p>
                <p class="txt-white">9 AM - 2 PM</p>
              </div>
            </div>
          </v-col>
          <v-col cols="12" lg="3">
            <div class="footer-block__social">
              <h6 class="bold txt-white">Siguenos en nuestras redes</h6>
              <div class="mt-5">
                <img class="mr-5" src="@/assets/img/icons/facebook.png" alt="">
                <img src="@/assets/img/icons/instagram.png" alt="">
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <div class="footer-block__contact">
              <h6 class="bold txt-white mb-5">Contacto</h6>
              <v-row no-gutters>
                <v-col cols="12" lg="6">
                  <!-- <div class="mb-5">
                    <img src="@/assets/img/icons/correo.png" alt="">
                    <p class="txt-white">contacto@acierconstrucciones.com</p>
                  </div> -->
                  <div class="footer-block__location">
                    <img src="@/assets/img/icons/ubicacion.png" alt="">
                    <p class="txt-white">Calle Lagos de moreno #4931, Col. Los Altos Monterrey N.L.</p>
                  </div>
                </v-col>
                <v-col cols="12" lg="3">
                  <a href="tel:5569796670" class="mb-5 footer-block__phone">
                    <img src="@/assets/img/icons/telefono-footer.png" alt="">
                    <p class="txt-white">55 6979 6670</p>
                  </a>
                  <a target="__blank" href="https://wa.me/5530041227" class="footer-block__whatsapp">
                    <img src="@/assets/img/icons/whatsapp-footer.png" alt="">
                    <p class="txt-white">55 3004 1227</p>
                  </a>
                </v-col>
              </v-row>
            </div>

          </v-col>
        </v-row>
      </div>
      <div class="footer-block__second-logo">
        <img src="@/assets/img/logos/ACIERnegativo.png" alt="">
      </div>
    </v-container>
  </div>
</template>

<style lang="scss" scoped>
  @import "@/assets/scss/Footer.scss";
</style>

<script>
  export default {
    name: "Footer",

    data: () => ({
      web_logo: 'ACIER-logo.png'
    }),
  };
</script>