import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"ContactForm"}},[_c(VContainer,{staticClass:"form-block",attrs:{"fluid":""}},[_c('div',{staticClass:"block-wrapper"},[_c(VForm,[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12","lg":"6"}},[_c(VTextField,{staticClass:"form-field bold",attrs:{"type":"text","background-color":"#E5E5E580","label":"Nombre","single-line":"","outlined":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c(VCol,{attrs:{"cols":"12","lg":"6"}},[_c(VTextField,{staticClass:"form-field bold",attrs:{"type":"email","background-color":"#E5E5E580","label":"Correo electrónico","single-line":"","outlined":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextarea,{staticClass:"form-field bold",attrs:{"type":"text","background-color":"#E5E5E580","label":"Mensaje","maxlength":"120","full-width":"","single-line":"","outlined":""},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c('button',{staticClass:"btn btn--primary lg-btn mx-auto"},[_c('span',[_vm._v("Enviar")])])])],1)],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }