<template>
    <div id="FurnitureDetailView">
        <v-container class="furniture-detail-block" fluid>
            <div class="block-wrapper">
                <v-row no-gutters>
                    <v-col cols="12" lg="7">
                        <v-container class="furniture-carousels-container">
                            <div class="furniture-main-carousel-container">
                                <VueSlickCarousel v-bind="furniture_main_carousel_settings"  @beforeChange="syncSliders" ref="slider-main" :asNavFor="$refs.c2"
                                    :focusOnSelect="true">
                                    <div v-for="furniture_photo in furniture_photos">
                                        <zoom-on-hover class="furniture-main-img rounded-img"
                                            :img-normal="require('@/assets/img/furnitures/mueble1/' + furniture_photo.photo)"
                                            :img-zoom="require('@/assets/img/furnitures/mueble1/' + furniture_photo.photo)"
                                            :scale="2">
                                        </zoom-on-hover>
                                        <div class="business-zoom-icon">
                                            <img src="@/assets/img/icons/zoom-galeria.png" alt="">
                                        </div>
                                    </div>

                                    <!-- open -->
                                    <!-- <div v-for="furniture_photo in furniture_photos" :key="i"
                                        @click.stop="image_open = true">
                                        <zoom-on-hover class="furniture-main-img rounded-img"
                                            :img-normal="require('@/assets/img/furnitures/mueble1/' + furniture_photo.photo)"
                                            :img-zoom="require('@/assets/img/furnitures/mueble1/' + furniture_photo.photo)"
                                            :scale="3">
                                        </zoom-on-hover>
                                    </div>
                                    <v-dialog v-for="furniture_photo in furniture_photos" :key="i" v-model="image_open"
                                        width="500">
                                        <v-card>
                                            <v-img
                                                :lazy-src="require('@/assets/img/furnitures/mueble1/' + furniture_photo.photo)"
                                                content-class="align-self-end" class="carousel-img rounded-img"
                                                width="100%" height="600"
                                                :src="require('@/assets/img/furnitures/mueble1/' + furniture_photo.photo)"
                                                gradient="to top, rgba(0,0,0,1), rgba(0,0,0,0.2)">
                                            </v-img>
                                        </v-card>
                                    </v-dialog> -->
                                    <!-- open -->

                                    <!-- <v-img v-for="furniture_photo in furniture_photos"
                                        :lazy-src="require('@/assets/img/furnitures/mueble1/' + furniture_photo.photo)"
                                        content-class="align-self-end" class="carousel-img rounded-img" width="50"
                                        height="600"
                                        :src="require('@/assets/img/furnitures/mueble1/' + furniture_photo.photo)"
                                        gradient="to top, rgba(0,0,0,1), rgba(0,0,0,0.2)">
                                    </v-img> -->
                                </VueSlickCarousel>
                            </div>
                            <div class="furniture-thumbnail-carousel-container mt-3">
                                <VueSlickCarousel @beforeChange="syncSliders" ref="slider-thumb" :asNavFor="$refs.c1"
                                    :slidesToShow="4" :focusOnSelect="true">
                                    <v-img v-for="furniture_photo in furniture_photos"
                                        :lazy-src="require('@/assets/img/furnitures/mueble1/' + furniture_photo.photo)"
                                        content-class="align-self-end" class="carousel-img rounded-img" height="10rem"
                                        :src="require('@/assets/img/furnitures/mueble1/' + furniture_photo.photo)"
                                        gradient="to top, rgba(0,0,0,1), rgba(0,0,0,0.2)">
                                    </v-img>
                                </VueSlickCarousel>
                            </div>
                        </v-container>
                    </v-col>
                    <v-col></v-col>
                    <v-col cols="12" lg="4">
                        <div class="furniture-data mt-2">
                            <div class="furniture-SKU mb-5">
                                <span class="">{{furniture_data.sku}}</span>
                            </div>
                            <h1 class="bold mb-5">{{furniture_data.name}}</h1>
                            <h6 class="bold mb-10">{{furniture_data.price}}</h6>
                            <p class="font-italic">{{furniture_data.description}}</p>

                            <div class="furniture-buy-cta mt-15">
                                <h6 class="bold text-center mb-5">¡Cómpralo!</h6>
                                <div>
                                    <div class="furniture-buy-phone mr-5">
                                        <v-img src="@/assets/img/icons/llamanos.png" contain width="30"></v-img>
                                        <p class="mt-3">Llámanos</p>
                                    </div>
                                    <div class="furniture-buy-whatsapp">
                                        <v-img src="@/assets/img/icons/whatsapp-contactanos.png" contain width="30">
                                        </v-img>
                                        <p class="mt-3">Contáctanos</p>
                                    </div>
                                </div>
                            </div>

                            <div class="furniture-specs">
                                <h6 class="bold mt-15 mb-10">Especificaciones</h6>
                                <div>
                                    <img class="furniture-icon mr-3" src="@/assets/img/icons/dimensiones.png">
                                    <p class="">Dimensiones</p>
                                </div>
                                <div class="furniture-specs__table mt-10 rounded-lg">
                                    <v-simple-table dense>
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-center bold">
                                                        Medidas
                                                    </th>
                                                    <th class="text-center">
                                                        Largo
                                                    </th>
                                                    <th class="text-center">
                                                        Ancho
                                                    </th>
                                                    <th class="text-center">
                                                        Alto
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td class="bold">Escritorio</td>
                                                    <td>{{ furniture_data.specs.large }}</td>
                                                    <td>{{ furniture_data.specs.width }}</td>
                                                    <td>{{ furniture_data.specs.height }}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </div>
                                <div class="furniture-specs__images mt-10">
                                    <div class="furniture-specs__img">
                                        <img :src="require('@/assets/img/furnitures/mueble1/' + furniture_data.specs_photo)"
                                            alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="furniture-materials-info mt-10">
                                <div>
                                    <img class="furniture-icon mr-3" src="@/assets/img/icons/materiales.png">
                                    <p class="">Materiales</p>
                                </div>
                                <p class="mt-5">{{furniture_data.materials}}</p>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </v-container>
    </div>
</template>

<style lang="scss">
    @import "@/assets/scss/FurnitureDetail.scss";
</style>

<script>
    import OwlCarousel from 'vue-owl-carousel'
    import VueSlickCarousel from 'vue-slick-carousel'
    // optional style for arrows & dots
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

    export default {
        name: "FurnitureDetailView",

        components: {
            OwlCarousel,
            VueSlickCarousel,
        },

        data: () => ({
            scrollPosition: null,
            image_open: false,
            selected_img: null,
            main_image: null,
            furniture_data: {
                sku: "COD 8999",
                name: 'Repisa Herreria Artesanal',
                price: "$12,000.00",
                description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. At ratione quidem iusto rem tempora odit, iure corrupti eos facilis porro, excepturi tenetur recusandae, dolore harum. Ut laudan ium consequuntur incidunt dolores.',
                materials: "Estructura de metal y MDF con chapa de abeto.",
                specs: {
                    large: "120 cm",
                    width: "60 cm",
                    height: "75 cm"
                },
                specs_photo: "specs.png",
            },
            furniture_main_carousel_settings: {
                "dots": true,
                /* asNavFor: $refs.c2,
                focusOnSelect: true,
                slidesToShow: 2 */
                /* "items": 1,
                "nav": true,
                "center": true,
                "loop": true,
                "navText": ["<img src='chevron-izq.png'>", "<img src='chevron-der.png'>"] */
            },
            furniture_thumbnail_carousel_settings: {
                /* "asNavFor": "$refs.c1",
                "focusOnSelect": true,
                "slidesToShow": 5 */
                /* "items": 1,
                "nav": true,
                "dots": false,
                "center": true,
                "loop": true,
                "navText": ["<img src='chevron-izq.png'>", "<img src='chevron-der.png'>"] */
            },
            furniture_photos: [{
                    photo: 'Img1.png',
                },
                {
                    photo: 'Img2.png',
                },
                {
                    photo: 'Img3.png',
                },
                {
                    photo: 'Img4.png',
                },
                {
                    photo: 'Img5.png',
                },
            ],
        }),
        methods: {
            /* updateScroll() {
            this.scrollPosition = window.scrollY
            }, */
            syncSliders(currentPosition, nextPosition) {
                this.$refs['slider-main'].goTo(nextPosition)
                this.$refs['slider-thumb'].goTo(nextPosition)
            }
        },
        mounted() {
            /* window.addEventListener('scroll', this.updateScroll); */
        }
    }
</script>