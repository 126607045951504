import { render, staticRenderFns } from "./FurnituresListView.vue?vue&type=template&id=1a98b7c5&"
import script from "./FurnituresListView.vue?vue&type=script&lang=js&"
export * from "./FurnituresListView.vue?vue&type=script&lang=js&"
import style0 from "./FurnituresListView.vue?vue&type=style&index=0&id=1a98b7c5&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports