<template>
  <div id="Menu">
    <v-app-bar height="100" color="white" flat fixed class="menu-navbar">
      <div class="block-wrapper d-flex align-center">
        <div class="d-flex flex-row align-center">
          <router-link to="/">
            <v-img to="" contain max-width="200" src="@/assets/img/logos/ACIER-logo.png"></v-img>
          </router-link>
          <div class="flex-column ml-10 d-none d-lg-flex">
            <div class="menu-navbar__schedule mb-3">
              <img src="@/assets/img/icons/reloj.png" alt="">
              <span>Lun - Sab : 9 AM - 8 PM / Dom : 9 AM - 2 PM</span>
            </div>
            <div class="menu-navbar__contact">
              <a href="tel:5569796670" class="contact-phone mr-4">
                <img src="@/assets/img/icons/telefono.png" alt="">
                <span>55 6979 6670</span>
              </a>
              <a target="__blank" href="https://wa.me/5530041227" class="contact-whatsapp">
                <img src="@/assets/img/icons/whatsapp.png" alt="">
                <span>55 3004 1227</span>
              </a>
            </div>
          </div>
        </div>
        <v-spacer></v-spacer>
        <v-list class="menu-items d-none d-lg-flex">
          <v-list-item active-class="menu-active" :to="menu_item.url" v-for="menu_item in menu_items">
            <v-list-item-title>{{menu_item.name}}</v-list-item-title>
          </v-list-item>
        </v-list>

        <div id="navIcon" @click="menu = !menu">
          <div v-bind:class="{'bar-on': menu}"></div>
        </div>

        
          <!-- <v-btn class="mx-2" fab dark color="#2E83FF" @click="menu = !menu">
            <v-icon dark v-if="menu == false">
              mdi-format-list-bulleted-square
            </v-icon>
            <v-icon dark v-if="menu == true">
              mdi-close
            </v-icon>
          </v-btn> -->
      </div>
    </v-app-bar>

    <!-- <div class="menu-mobile__backdrop" v-bind:class="{'show': menu}"></div> -->
    <v-container fluid class="menu-mobile" v-bind:class="{'show': menu}">
      <v-list class="menu-mobile__items">
        <v-list-item class="mb-15">
          <v-img to="/" contain max-width="200" src="@/assets/img/logos/ACIER-logo.png"></v-img>
        </v-list-item>
          <v-list-item active-class="active" :to="menu_item.url" v-for="menu_item in menu_items">
            <v-list-item-title>{{menu_item.name}}</v-list-item-title>
          </v-list-item>
        </v-list>
        <div class="menu-mobile__contact">
            <div class="menu-mobile__schedule mb-3">
              <img src="@/assets/img/icons/reloj.png" alt="">
              <div>
                <span>Lun - Sab : 9 AM - 8 PM</span>
                <span>Dom : 9 AM - 2 PM</span>
              </div>
            </div>
              <a href="tel:5569796670" class="contact-phone mb-3">
                <img src="@/assets/img/icons/telefono.png" alt="">
                <span>55 6979 6670</span>
              </a>
              <a target="__blank" href="https://wa.me/5530041227" class="contact-whatsapp mb-3">
                <img src="@/assets/img/icons/whatsapp.png" alt="">
                <span>55 3004 1227</span>
              </a>
            </div>
    </v-container>
  </div>
</template>

<style lang="scss" scoped>
  @import "@/assets/scss/Menu.scss";
</style>

<script>
  export default {
    name: "Menu",

    props: {
      userScrolled: Boolean
    },

    data: () => ({
      menu: false,
      menu_items: [{
          name: "Muebles",
          /* url: "/muebles" */
          url: "/proximamente"
        },
        {
          name: "Servicios Residenciales",
          /* url: "/servicios-residenciales" */
          url: "/proximamente"
        },
        {
          name: "Servicios Empresariales",
          /* url: "/servicios-empresariales" */
          url: "/proximamente"
        },
        {
          name: "Nosotros",
          url: "/about-us"
        },
      ]
    }),

    methods: {},

  };
</script>