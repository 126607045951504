<template>
    <div id="Furnitures">
        <v-container fluid class="furnitures-block">
            <div class="block-wrapper">
                <v-container fluid class="furnitures-grid">
                        <div class="w-100">
                            <h1 class="bold mb-10">MUEBLES</h1>
                        </div>
                        <v-row no-gutters class="mb-10">
                            <router-link class="furniture-column" to="/mueble-1" v-for="furniture in furnitures">
                                <v-img height="25rem" class="rounded-img" content-class="align-self-end"
                                    :src="require('@/assets/img/furnitures/' + furniture.photo)"
                                    gradient="to top, rgba(0,0,0,1), rgba(0,0,0,0.1)">
                                    <div class="furniture-column__info">
                                        <div class="furniture-tags mb-5">
                                            <template v-for="tag in furniture.tags">
                                                <div class="furniture-tag furniture" v-if="tag == 'Muebles'">
                                                    <span class="">{{tag}}</span>
                                                </div>
                                                <div class="furniture-tag shelf"  v-if="(tag == 'Repisas' || 'Sillas' || 'Mesas' || 'Estantes') && tag != 'Muebles'">
                                                    <span class="">{{tag}}</span>
                                                </div>
                                            </template>
                                            <!-- 
                                <div class="furniture-tag shelf" v-for="tag in furniture.tags" v-if="tag == 'Mueble'" >
                                    <span class="">{{tag}}</span>
                                </div> -->
                                            <!-- <div class="furniture-tag shelf">
                                    <span class="">{{furniture.tags}}</span>
                                </div> -->
                                        </div>
                                        <h5 class="txt-white bold">{{furniture.name}}</h5>
                                        <p class="txt-white light font-italic mt-4"><i>{{furniture.description}}</i></p>
                                    </div>
                                </v-img>
                            </router-link>
                        </v-row>
                        <v-col cols="12">
                            <a class="btn btn--primary lg-btn mx-auto">
                                <span>Ver proyectos</span>
                            </a>
                        </v-col>
                    </v-container>
            </div>
        </v-container>
    </div>
</template>

<style lang="scss">
    @import "@/assets/scss/FurnituresList.scss";
</style>


<script>
    export default {
        name: "FurnituresListView",

        data: () => ({
            furnitures: [{
                    name: 'Repisa Herreria Artesanal',
                    description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptas, amet.Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                    photo: 'Muebles1.png',
                    tags: ['Muebles', 'Repisas', ]
                },
                {
                    name: 'Librero Estante Herrería',
                    description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptas, amet.Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                    photo: 'Muebles2.png',
                    tags: ['Muebles', 'Estantes', ]
                },
                {
                    name: 'Mesa De Centro / Mármol',
                    description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptas, amet.Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                    photo: 'Muebles3.png',
                    tags: ['Muebles', 'Mesas', ]
                },
                {
                    name: 'Silla Herreria Artesanal',
                    description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptas, amet.Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                    photo: 'Muebles4.png',
                    tags: ['Muebles', 'Sillas', ]
                },
                {
                    name: 'Juego De Mesas Granito',
                    description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptas, amet.Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                    photo: 'Muebles5.png',
                    tags: ['Muebles', 'Mesas', ]
                },
                {
                    name: 'Repisa Herreria Artesanal',
                    description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptas, amet.Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                    photo: 'Muebles6.png',
                    tags: ['Muebles', 'Repisas', ]
                },
            ],
        }),
    }
</script>