<template>
  <v-app>
    <v-main v-scroll.self="onScroll">
      <Menu :userScrolled="userScrolled"></Menu>
      <!-- <Loader v-show="loading == true"></Loader> -->
      <div class="main-wrapper">
        <router-view />
      </div>
      <ContactForm></ContactForm>
      <Footer></Footer>
    </v-main>
  </v-app>
</template>

<style lang="scss">
@import "@/assets/scss/style.scss";
</style>

<script>
// @ is an alias to /src
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";
import Loader from "@/components/Loader.vue";
import ContactForm from "@/components/ContactForm.vue";

export default {
  name: "App",
  data: () => ({
    userScrolled: false,
    scrollValueY: 0,
    timerLoader: 3000,
    loading: true,
  }),
  components: {
    Menu,
    Footer,
    Loader,
    ContactForm,
  },

  mounted() { },

  created() {
    this.showLoader()
  },

  methods: {
    onScroll(e) {
      console.log("aaa")
      this.scrollValueY = e.target.scrollTop
      console.log("scroll Y", this.scrollValueY)
      console.log("scrolling", this.userScrolled)
      this.$emit('onScroll')


      if (this.scrollValueY > 500) {
        this.userScrolled = true
      } else {
        this.userScrolled = false
      }
    },
    showLoader() {
      setTimeout(() => {
        this.loading = false
      }, this.timerLoader)
    }
  }
};
</script>