<template>
    <div id="ContactForm">
        <v-container fluid class="form-block">
                <div class="block-wrapper">
                <v-form>
                    <v-container fluid>
                        <v-row no-gutters>
                            <v-col cols="12" lg="6">
                                <v-text-field class="form-field bold" v-model="name" type="text" background-color="#E5E5E580"
                                    label="Nombre" single-line outlined></v-text-field>
                            </v-col>
                            <v-col cols="12" lg="6">
                                <v-text-field class="form-field bold" v-model="email" type="email"
                                    background-color="#E5E5E580" label="Correo electrónico" single-line outlined>
                                </v-text-field>
                            </v-col>

                            <v-col cols="12">
                                <v-textarea class="form-field bold" v-model="message" type="text" background-color="#E5E5E580"
                                    label="Mensaje" maxlength="120" full-width single-line outlined>
                                </v-textarea>
                            </v-col>
                            <v-col cols="12">
                                <button class="btn btn--primary lg-btn mx-auto">
                                    <span>Enviar</span>
                                </button>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </div>
        </v-container>
    </div>
</template>

<style lang="scss">
    @import "@/assets/scss/ContactForm.scss";
</style>


<script>
    export default {
        name: "ContactForm",

        data: () => ({
            name: '',
            email: '',
            message: '',
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+/.test(v) || 'E-mail must be valid',
            ],
        })
    }
</script>