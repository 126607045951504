import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import MenuIcon from 'vue-material-design-icons/Menu.vue';
import 'animate.css';
import ZoomOnHover from "vue-zoom-on-hover";

Vue.use(ZoomOnHover);

Vue.component('menu-icon', MenuIcon);

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,

  render: function (h) {
    return h(App);
  },
}).$mount("#app");
