<template>
  <div id="Home">

    <v-container fluid class="header-block__carousel px-0">
      <OwlCarousel v-if="carousel_items.length > 0" eager v-bind="header_carousel_settings" class="header-carousel">
        <div class="carousel-item" v-for="carousel_item in carousel_items">
          <v-img :lazy-src="require('@/assets/img/sliders/' + carousel_item.img_name)" content-class="align-self-end"
            class="carousel-img rounded-lg" height="100%"
            :src="require('@/assets/img/sliders/' + carousel_item.img_name)"
            gradient="to top, rgba(0,0,0,2), rgba(0,0,0,0.2)">
            <div class="carousel-text d-none">
              <h3 class="txt-white mb-4">{{carousel_item.title}}</h3>
              <p class="txt-white font-italic">{{carousel_item.text}}</p>
            </div>
          </v-img>
        </div>
      </OwlCarousel>
    </v-container>

    <v-container fluid class="px-0 service-products-block">
      <div class="block-wrapper">
        <h1 class="bold text-center">SERVICIOS Y PRODUCTOS</h1>

        <v-container fluid class="furnitures-block">
          <h2 class="bold mb-10">Muebles</h2>
            <v-row no-gutters class="d-lg-none d-xl-none">
              <OwlCarousel v-if="furniture_items.length > 0" class="furnitures-carousel"
                v-bind="furnitures_carousel_settings">
                <div class="furniture-column" v-for="furniture_item in furniture_items">
                  <img class="furniture-img rounded-img" :src="require('@/assets/img/home/' + furniture_item.img_name)"
                    alt="">
                  <div class="furniture-column__text">
                    <h5 class="txt-white bold mb-4">{{furniture_item.title}}</h5>
                    <p class="txt-white font-italic light">{{furniture_item.text}}</p>
                  </div>
                </div>
              </OwlCarousel>
              <router-link class="load-more__btn" to="/proximamente">
              <button class="btn btn--primary"><span>Ver más</span></button>
            </router-link>
            </v-row>
            <v-row no-gutters class="d-none d-lg-flex d-xl-flex">
              <div class="furnitures-grid">
                <div class="furniture-column" v-for="furniture_item in furniture_items">
                  <v-img height="22rem" class="rounded-img" content-class="align-self-end"
                    :src="require('@/assets/img/home/' + furniture_item.img_name)"
                    gradient="to top, rgba(0,0,0,1), rgba(0,0,0,0.2)">
                    <div class="furniture-column__text">
                      <h5 class="txt-white bold mb-4">{{furniture_item.title}}</h5>
                      <p class="txt-white font-italic light">{{furniture_item.text}}</p>
                    </div>
                  </v-img>
                </div>
              </div>
              <router-link class="load-more__btn" to="/proximamente">
              <button class="btn btn--primary"><span>Ver más</span></button>
            </router-link>
          </v-row>
        </v-container>

        <v-container fluid class="residences-block">
          <h2 class="bold mb-10">Residenciales</h2>
          <v-row no-gutters>
            <div class="residences-grid">
              <div class="residence-column" v-for="residence_item in residence_items">
                <v-img height="22rem" class="rounded-img" content-class="align-self-end"
                  :src="require('@/assets/img/home/' + residence_item.img_name)"
                  gradient="to top, rgba(0,0,0,1), rgba(0,0,0,0.2)">
                  <div class="residence-column__text">
                    <h5 class="txt-white bold mb-4">{{residence_item.title}}</h5>
                    <p class="txt-white light font-italic">{{residence_item.text}}</p>
                  </div>
                </v-img>
              </div>
            </div>
          </v-row>
          <v-col cols="12">
            <router-link class="btn btn--primary lg-btn mx-auto" to="/proximamente">
              <span>Ver proyectos</span>
            </router-link>
          </v-col>
        </v-container>

        <v-container fluid class="business-block">
          <h2 class="bold mb-10">Empresariales</h2>
          <v-row no-gutters>
            <div class="business-grid">
              <div class="business-column" v-for="business_item in business_items">
                <v-img height="22rem" class="rounded-img" content-class="align-self-end"
                  :src="require('@/assets/img/home/' + business_item.img_name)"
                  gradient="to top, rgba(0,0,0,1), rgba(0,0,0,0.2)">
                  <div class="business-column__text">
                    <h5 class="txt-white bold mb-4">{{business_item.title}}</h5>
                    <p class="txt-white font-italic">{{business_item.text}}</p>
                  </div>
                </v-img>
              </div>
            </div>
          </v-row>
          <v-col cols="12">
            <router-link class="btn btn--primary lg-btn mx-auto" to="/proximamente">
              <span>Ver proyectos</span>
            </router-link>
          </v-col>
        </v-container>
      </div>
    </v-container>

    <v-container fluid class="companies-block my-15">
      <div class="block-wrapper">
        <OwlCarousel v-if="companies.length > 0" eager v-bind="company_carousel_settings" class="company-carousel">
          <div class="company-carousel__item" v-for="company in companies">
            <img :src="require('@/assets/img/clients/' + company.logo)" alt="">
            <span class="d-block text-center">{{company.name}}</span>
          </div>
        </OwlCarousel>
      </div>
    </v-container>

  </div>
</template>

<style lang="scss">
  @import "@/assets/scss/Home.scss";
</style>

<script>
  import OwlCarousel from 'vue-owl-carousel'

  export default {
    name: "HomeView",

    components: {
      OwlCarousel,
    },

    props: {},

    data: () => ({
      showdiv: true,
      currentSlide: 1,
      timerIntro: 2,
      icons_url: "@/assets/img/icons/",
      bgshow: false,
      header_carousel_settings: {
        "dots": false,
        "center": true,
        "loop": true,
        "navText": ["<img src='chevron-izq.png'>", "<img src='chevron-der.png'>"],
        "responsive": {
          0: {
            items: 1,
            dots: true,
            nav: false
          },
          600: {
            items: 1.5,
            nav: true
          },
          1920: {
            items: 1.5,
            nav: true
          }
        }
      },
      furnitures_carousel_settings: {
        "autoWidth": true,
        "dots": false,
        "loop": true,
        "margin": 20,
        "navText": ["<img src='chevron-izq.png'>", "<img src='chevron-der.png'>"],
        /* "responsive": {
          0: {
            dots: true,
            nav: true
          },
          600: {
            dots: false,
            nav: true
          },
          1920: {
            items: 1,
            dots: false,
            nav: true
          } 
        } */
      },
      company_carousel_settings: {
        "autoplay": true,
        "margin": 50,
        "dots": true,
        "nav": false,
        /*  "autoWidth": true, */
        "loop": true,
        "responsive": {
          0: {
            items: 1,
            dots: true,
            nav: false
          },
          600: {
            items: 4,
            nav: false
          },
          1920: {
            items: 5,
            nav: true
          }
        }
      },
      carousel_items: [{
          title: 'Portones, puertas y protectores',
          text: 'Los proyectos realizados son la prueba más contundente de la combinación entre la habilidad artística y la experiencia con la que sacamos adelante tu proyecto.',
          img_name: 'Slide1.png'
        },
        {
          title: 'Pérgolas',
          text: 'Conformada por materiales metálicos y de alta calidad, para conformar estabilidad, resistencia y rigidez.',
          img_name: 'Slide2.png'
        },
        {
          title: 'Muebles',
          text: 'Un toque fresco y duradero. Renueva la apariencia de tu hogar y negocio con bellos muebles forjados a tu medida.',
          img_name: 'Slide3.png'
        },
        {
          title: 'Servicios empresariales',
          text: 'Nuestro personal trabaja de forma profesional y con muchos años de experiencia. Nuestra empresa se encuentra capacitado para crear y construir naves industriales, techumbres residenciales, palapas y todo lo referente a herrería industrial.',
          img_name: 'Slide4.png'
        },
      ],
      furniture_items: [{
          title: 'Estante Vertical en Madera de Pino y Herrería',
          text: 'Librero o estante vertical, fabricado en madera natural de pino 100% sustentable y herrería de acero.',
          img_name: 'Muebles1.png'
        },
        {
          title: 'Mesa de Centro Madera de Parota Herreria',
          text: 'Madera de Parota , Acero y Malla También se puede fabricar en madera de pino.',
          img_name: 'Muebles2.png'
        },
        {
          title: 'Mesa Sala Industrial Madera Herreria',
          text: 'Madera de Parota , Acero y Malla También se puede fabricar en madera de pino.',
          img_name: 'Muebles3.png'
        },
        /* {
          title: 'Mueble 4',
          text: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptas, amet.',
          img_name: 'Muebles3.png'
        }, */
      ],
      residence_items: [{
          title: 'Protectores',
          text: 'Fuerza, resistencia y durabilidad es lo que caracteriza a los diseños de nuestras protecciones de hierro para tu patrimonio. La seguridad para los seres queridos que amas es prioridad.',
          img_name: 'Residenciales1.png'
        },
        {
          title: 'Puertas',
          text: 'Las puertas de herrería son un elemento decorativo y de seguridad muy importante, que pueden tener un estilo o formas infinitas, según el gusto de cada cliente.',
          img_name: 'Residenciales2.png'
        },
        {
          title: 'Portones',
          text: 'Los proyectos realizados son la prueba más contundente de la combinación entre la habilidad artística y la experiencia con la que sacamos adelante cualquiera que sea su proyecto.',
          img_name: 'Residenciales3.png'
        },
        {
          title: 'Pérgolas',
          text: 'Conformada por materiales metálicos , fuertes de alta calidad, para conformar:estabilidad, resistencia y rigidez. Tienen estas propiedades debido a su estructura.',
          img_name: 'Residenciales4.png'
        },
      ],
      business_items: [{
          title: 'Corte Láser',
          text: 'Nuestro personal trabaja en forma profesional y con muchos años de experiencia, lo cual, nos ayuda a realizar nuestro trabajo de la mejor manera posible. Esta empresa se encuentra capacitada para crear y construir naves industriales.',
          img_name: 'Empresariales1.png'
        },
        {
          title: 'Proyectos Empresariales',
          text: 'Nuestro personal trabaja en forma profesional y con muchos años de experiencia, lo cual, nos ayuda a realizar nuestro trabajo de la mejor manera posible. Esta empresa se encuentra capacitada para crear y construir naves industriales.',
          img_name: 'Empresariales2.png'
        },
      ],
      companies: [{
          name: 'Construcción en acero',
          logo: 'Atecno.png'
        },
        {
          name: 'Empresa de construcción',
          logo: 'NivelUno.png'
        },
        {
          name: 'Constructora industrial',
          logo: 'Themple.png'
        },
        {
          name: 'Desarrollo inmobiliario',
          logo: 'Imobilem.png'
        },
        {
          name: 'Constructora',
          logo: 'DAGS.png'
        },
      ]
    }),

    methods: {
      /* getCarouselImg(img){
        return require('@/assets/img/${img}.jpg');
      } */
      /* onScroll(e) {
        this.scrollInvoked++
        console.log("aaa")
        this.scrollValueY = e.target.scrollTop
        console.log("scroll", this.scrollValueY)

        if (this.scrollValueY > 500) {
          this.bgshow = false
          console.log("scroll", this.bgshow)
        } else if (this.scrollValueY == 0) {
          this.bgshow = true
        }
      }, */
      /* 
          showIntro() {
            if (this.timerIntro > 0) {
              setTimeout(() => {
                this.timerIntro -= 1
                this.showIntro()
              }, 1000)
            }
            this.bgshow = true
          } */
    },

    mounted() {
      /* this.showIntro() */
    },

    created() {}

  }
</script>