<template>
    <div class="Loader">
        <div class="loader-container">
            <span class="loader"></span>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import "@/assets/scss/Loader.scss";
</style>

<script>
    export default {
        name: "Loader",

        data: () => ({
        }),

        methods: {
            
        },

        created () {
        }
    }
</script>