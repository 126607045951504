<template>
    <div id="AboutUs">
        <v-container class="about-us-info-block px-0" fluid>
            <v-img content-class="align-self-end my-10" lazy-src="@/assets/img/aboutus/Header.png" src="@/assets/img/aboutus/Header.png" gradient="to top, rgba(0,0,0,1), rgba(0,0,0,0.2)">
                <v-container class="about-us-header text-center">
                    <v-row no-gutters>
                        <v-col cols="12" lg="4" class="px-15">
                            <h5 class="bold txt-white mb-10">Nosotros</h5>
                            <p class="txt-white light font-italic">ACIER Construcciones y Estructuras Metálicas, es una empresa formada en el 2010 para brindar servicios de fabricación y montaje DE ESTRUCTURA METALICA ASI COMO HERRERIA (PORTONES Y PROTECTORES), TECHUMBRES RESIDÉNCIALES Y PALAPAS.</p>
                        </v-col>
                        <v-col cols="12" lg="4" class="border-between px-15">
                            <h5 class="bold txt-white mb-10">Misión</h5>
                            <p class="txt-white light font-italic">ACIER Construcciones y Estructuras Metálicas, es una empresa formada en el 2010 para brindar servicios de fabricación y montaje DE ESTRUCTURA METALICA ASI COMO HERRERIA (PORTONES Y PROTECTORES), TECHUMBRES RESIDÉNCIALES Y PALAPAS.</p>
                        </v-col>
                        <v-col cols="12" lg="4" class="px-15">
                            <h5 class="bold txt-white mb-10">Visión</h5>
                            <p class="txt-white light font-italic">ACIER Construcciones y Estructuras Metálicas, es una empresa formada en el 2010 para brindar servicios de fabricación y montaje DE ESTRUCTURA METALICA ASI COMO HERRERIA (PORTONES Y PROTECTORES), TECHUMBRES RESIDÉNCIALES Y PALAPAS.</p>
                        </v-col>
                    </v-row>
                </v-container>
            </v-img>

            <v-container fluid class="team-block mt-10">
                <div class="block-wrapper">
                    <h4 class="bold text-center mb-15">CONOCE A QUIENES LO HACEN POSIBLE</h4>
                    <v-row no-gutters class="d-flex justify-space-around">
                        <div class="team-column" v-for="team_item in team_items">    
                            <v-img height="550" class="rounded-img" content-class="align-self-end"
                            :src="require('@/assets/img/aboutus/' + team_item.photo)"
                            gradient="to top, rgba(0,0,0,1), rgba(0,0,0,0.2)">
                            <div class="team-column__text">
                                <h5 class="txt-white bold">{{team_item.name}}</h5>
                                <p class="txt-white bold mb-4">{{team_item.position}}</p>
                                <p class="txt-white light font-italic mt-4"><i>{{team_item.description}}</i></p>
                            </div>
                        </v-img>
                    </div>
                </v-row>
            </div>
        </v-container>
        </v-container>
    </div>
</template>

<style lang="scss">
    @import "@/assets/scss/AboutUs.scss";
</style>

<script>
    export default {
        name: "AboutUsView",

        data: () => ({
            team_items: [{
                    name: 'Wade Warren',
                    position: 'Gerente de Obra',
                    description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptas, amet.Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                    photo: 'Persona1.png'
                },
                {
                    name: 'Ronald Richards',
                    position: 'Director General',
                    description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptas, amet.Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                    photo: 'Persona2.png'
                },
                {
                    name: 'Robert Fox',
                    position: 'Gerente de Producción',
                    description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptas, amet.Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                    photo: 'Persona3.png'
                },
            ],
        }),
    }
</script>