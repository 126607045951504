<template>
    <div id="ResidentialServices">
        <v-container fluid class="furnitures-block">
            <div class="block-wrapper">
                <!-- protectores -->
                <v-container fluid class="furnitures-grid">
                    <div class="w-100">
                        <h1 class="bold mb-10">PROTECTORES</h1>
                    </div>
                    <v-row no-gutters class="mb-10">
                        <router-link class="furniture-column" v-for="protector in protectors" :to="protector.url">
                            <v-img class="rounded-img" content-class="align-self-end"
                                :src="require('@/assets/img/residential/protectors/' + protector.photo)"
                                gradient="to top, rgba(0,0,0,1), rgba(0,0,0,0.2)">
                                <div class="furniture-column__info">
                                    <div class="furniture-tags mb-5">
                                        <template v-for="tag in protector.tags">
                                            <div class="furniture-tag furniture" v-if="tag == 'Residenciales'">
                                                <span class="">{{tag}}</span>
                                            </div>
                                            <div class="furniture-tag shelf" v-if="(tag == 'Protectores' || 'Puertas' || 'Portones' || 'Pérgolas') && tag != 'Residenciales'">
                                                <span class="">{{tag}}</span>
                                            </div>
                                        </template>
                                    </div>
                                    <h5 class="txt-white bold">{{protector.name}}</h5>
                                    <p class="txt-white light font-italic mt-4"><i>{{protector.description}}</i></p>
                                </div>
                            </v-img>
                        </router-link>
                    </v-row>
                    <v-col cols="12">
                        <a class="btn btn--primary lg-btn mx-auto">
                            <span>Ver más</span>
                        </a>
                    </v-col>
                </v-container>

                <!-- puertas -->
                <v-container fluid class="furnitures-grid">
                    <div class="w-100">
                        <h1 class="bold mb-10 mt-10">PUERTAS</h1>
                    </div>
                    <v-row no-gutters class="mb-10">
                        <router-link class="furniture-column" v-for="door in doors" :to="door.url">
                            <v-img class="rounded-img" content-class="align-self-end"
                                :src="require('@/assets/img/residential/doors/' + door.photo)"
                                gradient="to top, rgba(0,0,0,1), rgba(0,0,0,0.2)">
                                <div class="furniture-column__info">
                                    <div class="furniture-tags mb-5">
                                        <template v-for="tag in door.tags">
                                            <div class="furniture-tag furniture" v-if="tag == 'Residenciales'">
                                                <span class="">{{tag}}</span>
                                            </div>
                                            <div class="furniture-tag shelf" v-if="(tag == 'Protectores' || 'Puertas' || 'Portones' || 'Pérgolas') && tag != 'Residenciales'">
                                                <span class="">{{tag}}</span>
                                            </div>
                                        </template>
                                    </div>
                                    <h5 class="txt-white bold">{{door.name}}</h5>
                                    <p class="txt-white light font-italic mt-4"><i>{{door.description}}</i></p>
                                </div>
                            </v-img>
                        </router-link>
                    </v-row>
                    <v-col cols="12">
                        <a class="btn btn--primary lg-btn mx-auto">
                            <span>Ver más</span>
                        </a>
                    </v-col>
                </v-container>

                <!-- portones -->
                <v-container fluid class="furnitures-grid">
                    <div class="w-100">
                        <h1 class="bold mb-10">PORTONES</h1>
                    </div>
                    <v-row no-gutters class="mb-10">
                            <router-link class="furniture-column" v-for="gate in gates" :to="gate.url">
                                <v-img class="rounded-img" content-class="align-self-end"
                                    :src="require('@/assets/img/residential/gates/' + gate.photo)"
                                    gradient="to top, rgba(0,0,0,1), rgba(0,0,0,0.2)">
                                    <div class="furniture-column__info">
                                        <div class="furniture-tags mb-5">
                                            <template v-for="tag in gate.tags">
                                                <div class="furniture-tag furniture" v-if="tag == 'Residenciales'">
                                                <span class="">{{tag}}</span>
                                            </div>
                                            <div class="furniture-tag shelf" v-if="(tag == 'Protectores' || 'Puertas' || 'Portones' || 'Pérgolas') && tag != 'Residenciales'">
                                                <span class="">{{tag}}</span>
                                            </div>
                                            </template>
                                        </div>
                                        <h5 class="txt-white bold">{{gate.name}}</h5>
                                        <p class="txt-white light font-italic mt-4"><i>{{gate.description}}</i></p>
                                    </div>
                                </v-img>
                            </router-link>
                        </v-row>
                        <v-col cols="12">
                            <a class="btn btn--primary lg-btn mx-auto">
                                <span>Ver más</span>
                            </a>
                        </v-col>
                </v-container>

                <!-- pergolas -->
                <v-container fluid class="furnitures-grid">
                    <div class="w-100">
                        <h1 class="bold mb-10">PERGOLAS</h1>
                    </div>
                    <v-row no-gutters class="mb-10">
                            <router-link class="furniture-column" v-for="pergola in pergolas" :to="pergola.url">
                                <v-img class="rounded-img" content-class="align-self-end"
                                    :src="require('@/assets/img/residential/pergolas/' + pergola.photo)"
                                    gradient="to top, rgba(0,0,0,1), rgba(0,0,0,0.2)">
                                    <div class="furniture-column__info">
                                        <div class="furniture-tags mb-5">
                                            <template v-for="tag in pergola.tags">
                                                <div class="furniture-tag furniture" v-if="tag == 'Residenciales'">
                                                <span class="">{{tag}}</span>
                                            </div>
                                            <div class="furniture-tag shelf" v-if="(tag == 'Protectores' || 'Puertas' || 'Portones' || 'Pérgolas') && tag != 'Residenciales'">
                                                <span class="">{{tag}}</span>
                                            </div>
                                            </template>
                                        </div>
                                        <h5 class="txt-white bold">{{pergola.name}}</h5>
                                        <p class="txt-white light font-italic mt-4"><i>{{pergola.description}}</i></p>
                                    </div>
                                </v-img>
                            </router-link>
                    </v-row>
                        <v-col cols="12">
                            <a class="btn btn--primary lg-btn mx-auto">
                                <span>Ver más</span>
                            </a>
                        </v-col>
                </v-container>
            </div>
        </v-container>
    </div>
</template>

<style lang="scss">
    @import "@/assets/scss/ResidentialServicesList.scss";
</style>


<script>
    export default {
        name: "ResidentialServicesListView",

        data: () => ({
            protectors: [{
                    name: 'Repisa Herreria Artesanal',
                    description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptas, amet.Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                    photo: 'Protectores1.png',
                    tags: ['Residenciales', 'Protectores', ],
                    url: "/residencial-1"
                },
                {
                    name: 'Repisa Herreria Artesanal 2',
                    description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptas, amet.Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                    photo: 'Protectores2.png',
                    tags: ['Residenciales', 'Protectores', ],
                    url: "/residencial-1"
                },
                {
                    name: 'Repisa Herreria Artesanal 3',
                    description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptas, amet.Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                    photo: 'Protectores3.png',
                    tags: ['Residenciales', 'Protectores'],
                    url: "/residencial-1"
                },
            ],
            doors: [{
                    name: 'Repisa Herreria Artesanal',
                    description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptas, amet.Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                    photo: 'Puertas1.png',
                    tags: ['Residenciales', 'Puertas', ],
                    url: "/residencial-1"
                },
                {
                    name: 'Repisa Herreria Artesanal 2',
                    description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptas, amet.Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                    photo: 'Puertas2.png',
                    tags: ['Residenciales', 'Puertas', ],
                    url: "/residencial-1"
                },
                {
                    name: 'Repisa Herreria Artesanal 3',
                    description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptas, amet.Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                    photo: 'Puertas3.png',
                    tags: ['Residenciales', 'Puertas'],
                    url: "/residencial-1"
                },
            ],
            gates: [{
                    name: 'Repisa Herreria Artesanal',
                    description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptas, amet.Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                    photo: 'Portones1.png',
                    tags: ['Residenciales', 'Portones' ],
                    url: "/residencial-1"
                },
                {
                    name: 'Repisa Herreria Artesanal 2',
                    description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptas, amet.Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                    photo: 'Portones2.png',
                    tags: ['Residenciales', 'Portones' ],
                    url: "/residencial-1"
                },
                {
                    name: 'Repisa Herreria Artesanal 3',
                    description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptas, amet.Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                    photo: 'Portones3.png',
                    tags: ['Residenciales', 'Portones'],
                    url: "/residencial-1"
                },
            ],
            pergolas: [{
                    name: 'Repisa Herreria Artesanal',
                    description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptas, amet.Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                    photo: 'Pergolas1.png',
                    tags: ['Residenciales', 'Pérgolas' ],
                    url: "/residencial-1"
                },
                {
                    name: 'Repisa Herreria Artesanal 2',
                    description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptas, amet.Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                    photo: 'Pergolas2.png',
                    tags: ['Residenciales', 'Pérgolas' ],
                    url: "/residencial-1"
                },
                {
                    name: 'Repisa Herreria Artesanal 3',
                    description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptas, amet.Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                    photo: 'Pergolas3.png',
                    tags: ['Residenciales', 'Pérgolas'],
                    url: "/residencial-1"
                },
            ],
        }),
    }
</script>