import Vue from "vue";
import VueRouter from "vue-router";
import ComingSoonView from "../views/ComingSoonView.vue";
import HomeView from "../views/HomeView.vue";
import AboutUsView from "../views/AboutUsView.vue";
import FurnituresListView from "../views/FurnituresListView.vue";
import FurnitureDetailView from "../views/FurnitureDetailView.vue";
import ResidentialServicesListView from "../views/ResidentialServicesListView.vue";
import ResidentialServiceDetailView from "../views/ResidentialServiceDetailView.vue";
import BusinessServicesListView from "../views/BusinessServicesListView.vue";
import BusinessServiceDetailView from "../views/BusinessServiceDetail.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/proximamente",
    name: "proximamente",
    component: ComingSoonView,
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about-us",
    name: "about-us",
    component: AboutUsView,
  },
  {
    path: "/muebles",
    name: "muebles",
    component: FurnituresListView,
  },
  {
    path: "/mueble-1",
    name: "mueble",
    component: FurnitureDetailView,
  },
  {
    path: "/servicios-residenciales",
    name: "servicios-residenciales",
    component: ResidentialServicesListView,
  },
  {
    path: "/residencial-1",
    name: "servicio-residencial",
    component: ResidentialServiceDetailView,
  },
  {
    path: "/servicios-empresariales",
    name: "servicios-empresariales",
    component: BusinessServicesListView,
  },
  {
    path: "/empresarial-1",
    name: "servicio-empresarial",
    component: BusinessServiceDetailView,
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,

  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
});


export default router;