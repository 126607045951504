<template>
    <div id="BusinessServices">
        <v-container class="furnitures-block" fluid>
            <div class="block-wrapper">
            <v-container fluid class="furnitures-grid">
                <div class="w-100">
                    <h1 class="bold mb-10">EMPRESARIALES</h1>
                </div>
                <v-row no-gutters class="mb-10">
                        <router-link class="furniture-column" v-for="service in business_services" to="/empresarial-1">
                        <v-img class="rounded-img" content-class="align-self-end"
                            :src="require('@/assets/img/business/' + service.photo)"
                            gradient="to top, rgba(0,0,0,1), rgba(0,0,0,0.2)">
                            <div class="furniture-column__info">
                                <div class="furniture-tags mb-5">
                                    <template v-for="tag in service.tags">
                                        <div class="furniture-tag furniture" v-if="tag == 'Empresariales'">
                                            <span class="">{{tag}}</span>
                                        </div>
                                        <div class="furniture-tag shelf" v-if="(tag == 'Herreria' || 'Estructura' || 'Soportes' || 'Andamios') && tag != 'Empresariales'">
                                            <span class="">{{tag}}</span>
                                        </div>
                                    </template>
                                    <!-- 
                                <div class="furniture-tag shelf" v-for="tag in furniture.tags" v-if="tag == 'Mueble'" >
                                    <span class="">{{tag}}</span>
                                </div> -->
                                    <!-- <div class="furniture-tag shelf">
                                    <span class="">{{furniture.tags}}</span>
                                </div> -->
                                </div>
                                <h5 class="txt-white bold">{{service.name}}</h5>
                                <p class="txt-white light font-italic mt-4"><i>{{service.description}}</i></p>
                            </div>
                        </v-img>
                    </router-link>
                </v-row>
                    <v-col cols="12">
                        <a class="btn btn--primary lg-btn mx-auto">
                            <span>Ver proyectos</span>
                        </a>
                    </v-col>
            </v-container>
        </div>
        </v-container>
    </div>
</template>

<style lang="scss">
    @import "@/assets/scss/BusinessServicesList.scss";
</style>


<script>
    export default {
        name: "ResidentialServicesListView",

        data: () => ({
            business_services: [{
                    name: 'Nombre',
                    description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptas, amet.Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                    photo: 'Empresarial1.png',
                    tags: ['Empresariales', 'Herrería', ]
                },
                {
                    name: 'Nombre',
                    description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptas, amet.Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                    photo: 'Empresarial2.png',
                    tags: ['Empresariales', 'Soportes', ]
                },
                {
                    name: 'Nombre',
                    description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptas, amet.Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                    photo: 'Empresarial3.png',
                    tags: ['Empresariales', 'Andamios']
                },
                {
                    name: 'Nombre',
                    description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptas, amet.Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                    photo: 'Empresarial4.png',
                    tags: ['Empresariales', 'Estructura']
                },
                {
                    name: 'Nombre',
                    description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptas, amet.Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                    photo: 'Empresarial5.png',
                    tags: ['Empresariales', 'Soportes', ]
                },
                {
                    name: 'Nombre',
                    description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptas, amet.Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
                    photo: 'Empresarial6.png',
                    tags: ['Empresariales', 'Andamios', ]
                },
            ],
        }),
    }
</script>